import React, { useState, useMemo, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IntegrationIcon from '@material-ui/icons/CallMerge';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { WebHookContextProvider } from './WebHookContext';
import WebHookCard from './WebHookCard';
import WebHookAddItDialog from './WebHookAddItDialog';
import TokenDialog from './TokenDialog';
import { WEBHOOK_TYPES } from './constants';
import { sortByNum, sortByAlphabet } from '@/utils';
import {
	useFilteredList,
	ListWithSidebar,
	ListFilterProvider,
	ListControls,
} from '@/components/FilteredLists';
import WebHookListFilters from './WebHookListFilters';
import { useWebHookContext } from './WebHookContext';
import { useAppContext } from '@/components/AppContext';
import { PageHeader } from '@/components/App';
import { AddCard, constants, EmptyState, ActionButton, Grid } from '@/components/Layout';
import { useHasPermission, NoPermissionTooltip } from '@/components/Permission';
import { rnd } from '@/utils';

const useStyles = makeStyles(theme => ({
	editorAreaWrap: {
		minWidth: '80%',
		maxWidth: '80%',
		minHeight: '85%',
		height: '100%',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
	},
	editorAreaWrapSm: {
		maxWidth: '90%',
	},
	editorAreaWrapXs: {
		maxWidth: '95%',
	},
	editorArea: {
		position: 'relative',
		flexGrow: 1,
		borderRadius: theme.shape.borderRadius,
		border: `3px dashed ${theme.palette.grey[300]}`,
		padding: theme.spacing(1),
	},
	centerContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

function WebhookListContent({ filterState }) {
	const { app } = useAppContext();
	const { state, api } = useWebHookContext();
	const { t } = useTranslation();
	const hasEditPermission = useHasPermission();

	const addKey = useRef(rnd());

	const [addOpen, setAddOpen] = useState(false);

	const { filters, other, sort, order, hasFilters } = filterState;

	const filteredHooks = useMemo(() => {
		const hooks =
			Object.keys(filters).length > 0
				? state.hooks.filter(hook => {
						const eventFilterPassed = filters.webhooks
							? filters.webhooks === hook.event_type
							: true;

						const typeFilterPassed = filters.integrations
							? filters.integrations.indexOf(hook.webhook_type) > -1
							: true;

						const searchFilterPassed = filters.search
							? hook.name.indexOf(filters.search) > -1
							: true;

						return eventFilterPassed && typeFilterPassed && searchFilterPassed;
				  })
				: state.hooks;

		return sort === 'fname'
			? sortByAlphabet(hooks, 'name', order === 'desc')
			: sortByNum(hooks, 'timestamp', order === 'desc');
	}, [filters, sort, order, state.hooks]);

	return (
		<>
			{filteredHooks.length > 0 && (
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						md={3}
						sm={4}
						xs={6}
					>
						<NoPermissionTooltip>
							<AddCard
								onClick={() => setAddOpen(true)}
								label={t`New integration`}
								maxReached={state.hooks.length >= app.package.webhooks}
								subject={t`webhooks`}
								disabled={!hasEditPermission}
							/>
						</NoPermissionTooltip>
					</Grid>
					{filteredHooks.map(hook => {
						return (
							<Grid
								item
								md={3}
								sm={4}
								xs={6}
								key={hook.webhook_id}
							>
								<WebHookCard hook={hook} />
							</Grid>
						);
					})}
				</Grid>
			)}
			{filteredHooks.length === 0 && (
				<EmptyState
					image={<img src={`/assets/img/r/emptystate/webhooks.gif`} />}
					primary={
						state.hooks.length > 0
							? t`integrations-webhook-emptystate_filter-title`
							: t`integrations-webhook-emptystate_default-title`
					}
					secondary={
						state.hooks.length > 0
							? t`integrations-webhook-emptystate_filter-text`
							: t`integrations-webhook-emptystate_default-text`
					}
					action={
						state.hooks.length == 0 && (
							<NoPermissionTooltip>
								<ActionButton
									variant="contained"
									color="primary"
									onClick={() => setAddOpen(true)}
									disabled={!hasEditPermission}
								>
									{t`integrations-webhook-emptystate_default-button_create_integration`}
								</ActionButton>
							</NoPermissionTooltip>
						)
					}
				/>
			)}

			<WebHookAddItDialog
				key={`add-webhook-${addKey.current}`}
				open={addOpen}
				onClose={e => {
					setAddOpen(false);
					if (e?.refreshComponent) setTimeout(() => (addKey.current = rnd()), 300);
				}}
			/>
		</>
	);
}

export default function WebHookList() {
	const { t } = useTranslation();

	const [filtersOpen, setFiltersOpen] = useState(
		window.innerWidth > constants.filterDrawerBreakpoint
	);

	const filterState = useFilteredList({
		filters: {},
	});

	return (
		<>
			<PageHeader title={t`Webhook & App integrations`} />

			<ListFilterProvider
				{...filterState}
				singleFilters={['webhooks', 'search']}
				multiFilters={['integrations']}
				filterLabels={{
					webhooks: {
						label: t`Send event`,
						user_init: t`Manual by user`,
						created: t`On new feedback`,
					},
					integrations: WEBHOOK_TYPES.reduce(
						(hooks, hook) => {
							hooks[hook.value] = t(hook.label);
							return hooks;
						},
						{ label: 'Integration type' }
					),
				}}
			>
				<ListWithSidebar
					drawerContent={<WebHookListFilters />}
					open={filtersOpen}
					onClose={() => setFiltersOpen(false)}
				>
					<ListControls
						sortOptions={[
							{ label: t('Date: old to new'), value: 'date-asc' },
							{ label: t('Date: new to old'), value: 'date-desc' },
							{ label: t('Integration name: A to Z'), value: 'fname-asc' },
							{ label: t('Integration name: Z to A'), value: 'fname-desc' },
						]}
						toggleFilterDrawer={() => setFiltersOpen(prev => !prev)}
					/>

					<WebHookContextProvider>
						<WebhookListContent filterState={filterState} />
					</WebHookContextProvider>
					<TokenDialog />
				</ListWithSidebar>
			</ListFilterProvider>
		</>
	);
}
