import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { useListFilterContext, FilterGroup } from '../FilteredLists';
import { useTranslation } from 'react-i18next';

import { WEBHOOK_TYPES } from './constants';

function WebHookListFilters({ ...props }) {
	const { setSingleFilter, setMultiFilter, filters, sort } = useListFilterContext();

	const { t } = useTranslation();

	const integrations = WEBHOOK_TYPES.map(hook => {
		return { label: t(hook.label), value: hook.value };
	});

	return (
		<Fragment>
			<div>
				<TextField
					variant="filled"
					fullWidth
					placeholder={t('Search')}
					label={t('Search')}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
					onChange={e => setSingleFilter('search', e.target.value)}
					value={filters.search ?? ''}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Send event')}
					filterKey={'webhooks'}
					filters={[
						{ label: t('Manual by user'), value: 'user_init' },
						{ label: t('On new feedback'), value: 'created' },
					].map(filter => {
						if (filter.value === filters.webhooks) {
							filter.selected = true;
						}
						return filter;
					})}
					onChange={({ key, value }) => {
						setSingleFilter(key, value);
					}}
				/>
			</div>

			<div>
				<FilterGroup
					filterName={t('Integration type')}
					filterKey={'integrations'}
					filters={integrations.map(filter => {
						if ((filters.integrations || []).indexOf(filter.value) > -1) {
							filter.selected = true;
						}
						return filter;
					})}
					multiple
					onChange={({ key, value }) => {
						setMultiFilter(key, value);
					}}
				/>
			</div>
		</Fragment>
	);
}

export default WebHookListFilters;
