import React, { useEffect, useState, useRef, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { Trans, useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import { deepEqual } from '@/utils';
import { useAppContext } from '@/components/AppContext';
import { DataSourceAutocomplete, DataSourceItem } from '@/components/DataSources';
import { ValidatedField } from '@/components/Validation';
import {
	EditorNestedOrAndArrayConditions,
	SwitchWithLabel,
	ButtonAddList,
	EditorOptionList,
	EditorOptionExpansionPanel,
	EditorListRadioGroup,
	ActionIconButton,
	Grid,
	Typography,
	TextField,
	MenuItem,
	Box,
	FormControl,
	FormControlLabel,
	Checkbox,
	DrawerWithAppBar,
	CardContent,
	Card,
	SimpleDialog,
	ActionButton,
	ListItemText,
	ListItemIcon,
	Tooltip,
} from '@/components/Layout';
import { InfoIcon } from '@/components/Icons';
import { format, isValid } from '@/utils/date';
import {
	HasPermissionWithEmptyState,
	permissionModules,
	permissionSubjects,
} from '@/components/Permission';
import { useTrackingEvents } from '@/components/Tracking';
import { useDeploymentStore } from './DeploymentStore';
import DeploymentIcon from './DeploymentIcon';

import {
	innerConditionBase,
	operators,
	isMultipleCssSelector,
	eventOptions,
} from './deploymentUtils';
import DeploymentEditorMultipleValuesAutocomplete from './DeploymentEditorMultipleValuesAutocomplete';
import AddConditionPopover from './AddConditionPopover';
import SaveConditionDialog from './SaveConditionDialog';

const useStyles = makeStyles(theme => ({
	tab: {
		height: '100%',
	},
	tabPadding: {
		padding: theme.spacing(2, 1),
	},
	tabSpaceTop: {
		paddingTop: theme.spacing(1),
	},
	alignTextWithInput: {
		verticalAlign: 'middle',
	},
	inputInText: {
		margin: theme.spacing(0, 1),
		width: 100,
	},
	selectInput: {
		width: 'auto',
	},
	datePickerInput: {
		width: 140,
	},
	numberInput: {
		width: 60,
	},
	addCondition: {
		margin: theme.spacing(2, 0),
	},
	thenMargin: {
		marginBottom: theme.spacing(2),
	},
	marginLeft: {
		marginLeft: theme.spacing(2),
	},
	caption: {
		color: theme.palette.grey[600],
		display: 'block',
	},
	renderInput: {
		width: 320,
	},
	addCopiedConditionFab: {
		position: 'fixed',
		bottom: theme.spacing(0),
		right: theme.spacing(2),
		zIndex: theme.zIndex.drawer + 1,
	},
	addConditionMenu: {
		zIndex: theme.zIndex.drawer + 2,
	},
	sessionTooltipList: {
		margin: 0,
		padding: theme.spacing(1),
	},
}));

const dateTimeOperators = [
	{ value: 'earlier', label: 'before' },
	{ value: 'exactly', label: 'exactly' },
	{ value: 'later', label: 'after' },
	{ value: 'between', label: 'between' },
];

export default function DeploymentEditorRuleDrawer({ editorType, scrollToNewRule }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const { app } = useAppContext();
	const selectedRule = useDeploymentStore(store => store.temporaryRule);
	const surveysByKey = useDeploymentStore(store => store.surveysByKey);
	const { enqueueSnackbar } = useSnackbar();
	const autocompleteSurveyOptions = useDeploymentStore(store => {
		if (editorType === 'sdk') {
			const sdkSurveys = store.surveysByProject.flatMap(project => project.sdk_surveys);
			const webSurveys = store.surveysByProject.flatMap(project => project.web_surveys);
			return [...sdkSurveys, ...webSurveys];
		}
		return store.surveysByProject.flatMap(project => project[`${editorType}_surveys`]);
	});
	const savedConditions = useDeploymentStore(store => store.savedConditions);
	const selectedRuleId = useDeploymentStore(store => store.selectedRuleId);
	const hasUnsavedRuleChanges = useDeploymentStore(store => store.hasUnsavedRuleChanges);
	const newRule = useDeploymentStore(store => store.newRule);
	const drawerOpen = useDeploymentStore(store => store.drawerOpen);
	const addConditionFabRef = useRef(null);
	const [addCopiedConditionMenuOpen, setAddCopiedConditionMenuOpen] = useState(false);
	const { track } = useTrackingEvents();
	const {
		createTemporaryRule,
		addCondition,
		removeCondition,
		setConditionOption,
		setNestedConditionOption,
		setMobileConditionOption,
		setNestedMobileConditionOption,
		addToConditionArray,
		removeFromConditionArray,
		setSurveyKey,
		setWebview,
		setRenderDiv,
		setRenderOption,
		saveTemporaryRule,
		discardTemporaryRule,
	} = useDeploymentStore(store => store.actions);

	const saveTemporaryRuleAndScroll = useCallback(() => {
		if (selectedRule?.if?.length === 0) {
			enqueueSnackbar(
				t`data_collection-deployment_editor_rule_drawer-condition_sets-save_condition_button-no_conditions`
			);
			return;
		}
		saveTemporaryRule();
		scrollToNewRule();
	}, [saveTemporaryRule, scrollToNewRule, selectedRule, t, enqueueSnackbar]);

	const [sidebarState, setSidebarState] = useImmer({
		addMenu: {},
		collapsed: {},
	});

	const selectedSurveyKey = selectedRule?.then?.[0].args[0];

	const surveyInRule = surveysByKey[selectedSurveyKey] || {};
	const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
	const [conditionToSave, setConditionToSave] = useState(null);

	const drawerContentRef = useRef(null);
	const conditionRefs = useRef({});
	const previousRuleIfLength = useRef(0);

	useEffect(() => {
		setTimeout(() => {
			if (previousRuleIfLength.current > selectedRule?.if?.length) {
				return;
			}
			const newIndex = (selectedRule?.if?.length || 1) - 1;
			const conditionElement = conditionRefs.current[newIndex];
			const scrollContainer = app.layout.drawerScrollerRef.current;

			if (conditionElement && scrollContainer) {
				const containerRect = scrollContainer.getBoundingClientRect();
				const elementRect = conditionElement.getBoundingClientRect();

				scrollContainer.scrollTo({
					top: elementRect.top - containerRect.top + scrollContainer.scrollTop - 64,
					behavior: 'smooth',
				});
			}
			previousRuleIfLength.current = selectedRule?.if?.length || 0;
		}, 0);
	}, [selectedRule?.if?.length]);

	const handleClose = () => {
		if (hasUnsavedRuleChanges) {
			setShowUnsavedDialog(true);
		} else {
			closeDrawer();
		}
	};

	const closeDrawer = () => {
		setShowUnsavedDialog(false);
		discardTemporaryRule();
		//onClose();
	};
	const [drawerTransitionCompleted, setDrawerTransitionCompleted] = useState(false);

	useEffect(() => {
		// When the drawer opens, delay setting the transition-complete flag by 300ms
		if (drawerOpen) {
			const timerId = setTimeout(() => {
				setDrawerTransitionCompleted(true);
			}, 300); // Adjust the time if your transition duration differs
			return () => clearTimeout(timerId);
		} else {
			setDrawerTransitionCompleted(false);
		}
	}, [drawerOpen]);

	return (
		<>
			<DrawerWithAppBar
				open={drawerOpen}
				onClose={handleClose}
				size="medium"
				ToolbarProps={{
					text:
						newRule && !selectedSurveyKey
							? t`data_collection-deployment_editor_rule_drawer-appbar_title-add_rule-form`
							: newRule && selectedSurveyKey
							? t`data_collection-deployment_editor_rule_drawer-appbar_title-add_rule-targeting`
							: t`data_collection-deployment_editor_rule_drawer-appbar_title-edit_rule`,
				}}
				ToolbarContent={
					<ActionButton
						disabled={!selectedSurveyKey}
						action="save"
						variant="contained"
						onClick={saveTemporaryRuleAndScroll}
						data-test-element="save-rule-button"
						data-track-event="deployment_editor_save_rule"
					>
						{t`data_collection-deployment_editor_rule_drawer-appbar_title-save`}
					</ActionButton>
				}
			>
				<>
					<HasPermissionWithEmptyState
						module={permissionModules.data_collection}
						subject={permissionSubjects.deployments}
					>
						<div
							ref={drawerContentRef}
							className={`${classes.tab} ${classes.tabPadding}`}
						>
							{drawerOpen && (
								<>
									<Card>
										<CardContent>
											<Typography
												variant="subtitle"
												mb={2}
											>
												{!selectedSurveyKey
													? t`data_collection-deployment_editor_rule_drawer-select_form-title`
													: t`data_collection-deployment_editor_rule_drawer-selected_form-title`}
											</Typography>
											{selectedSurveyKey && (
												<DataSourceItem
													survey_type={surveyInRule.survey_type}
													survey_format={surveyInRule.survey_format}
													campaign={surveyInRule.campaign}
													title={surveyInRule.name}
													dataSource={surveyInRule}
													button={false}
													onEdit={
														newRule
															? () => {
																	setSurveyKey({ value: '' });
															  }
															: null
													}
													disablePadding
												/>
											)}
											{!selectedSurveyKey && (
												<DataSourceAutocomplete
													options={autocompleteSurveyOptions}
													variant="outlined"
													onChange={(e, value) => {
														if (!selectedRule) {
															createTemporaryRule({
																surveyKey: value?.survey_key,
																domain: app.domain,
																editorType,
																sdkType:
																	value?.campaign === 'web'
																		? 'webview'
																		: value?.sdk_type ?? 'native',
																surveyFormat: value?.survey_format ?? 'form',
															});
														} else {
															setSurveyKey({ value: value?.survey_key });
															if (editorType === 'sdk') {
																setRenderOption({
																	option: 'webview',
																	value:
																		value?.campaign === 'web' ||
																		value?.sdk_type === 'webview',
																});
															}
														}
													}}
													//value={selectedSurveyKey}
													autoFocus
													open={drawerTransitionCompleted}
													//disabled={!showAutocomplete}
												/>
											)}
										</CardContent>
									</Card>

									{selectedRule && (
										<>
											<Typography
												variant="body2"
												color="textSecondary"
												mb={2}
											>
												{t(
													`data_collection-deployment_editor_rule_drawer-condition_sets-helper-${editorType}`
												)}
											</Typography>

											<div>
												{selectedRule.if?.map((conditionSet, conditionIndex) => {
													const setOption = (key, value) => {
														setConditionOption({
															conditionIndex,
															condition: key,
															value,
														});
													};

													const setNestedOption = (
														key,
														value,
														nested,
														nested2,
														nested3
													) => {
														setNestedConditionOption({
															conditionIndex,
															condition: key,
															value,
															nested,
															nested2,
															nested3,
														});
													};

													const setMobileOption = (key, value, nested) => {
														setMobileConditionOption({
															conditionIndex,
															condition: key,
															nested,
															value,
														});
													};

													const setNestedMobileOption = ({
														condition,
														nested,
														nested2,
														nested3,
														value,
													}) => {
														setNestedMobileConditionOption({
															conditionIndex,
															condition,
															nested,
															nested2,
															nested3,
															value,
														});
													};

													const addArrayOption = (key, value, conditionOrIndex) => {
														addToConditionArray({
															conditionIndex,
															condition: key,
															conditionOrIndex,
															value,
														});
													};
													const removeArrayOption = (key, index, orIndex) => {
														removeFromConditionArray({
															conditionIndex,
															condition: key,
															index,
															orIndex,
														});
													};

													const isSaved = savedConditions.some(savedCondition => {
														return deepEqual(conditionSet, savedCondition.condition_set);
													});

													return (
														<EditorOptionExpansionPanel
															ref={el => (conditionRefs.current[conditionIndex] = el)}
															title={t(
																`data_collection-deployment_editor_rule_drawer-condition_sets-title`,
																{
																	count: conditionIndex + 1,
																	trigger: t(
																		`data_collection-deployment_editor_rule_drawer-${
																			editorType === 'sdk' ? 'mobile-' : ''
																		}trigger${surveyInRule.modal === 0 ? '-embed' : ''}-${
																			conditionSet.trigger
																		}-primary`
																	),
																}
															)}
															summaryActions={
																<>
																	<ActionIconButton
																		disabled={isSaved}
																		action={isSaved ? 'bookmarked' : 'bookmark'}
																		data-test-element="save-condition-button"
																		data-track-event="deployment_editor_save_condition_set_button"
																		tooltip={
																			isSaved
																				? t`data_collection-deployment_editor_rule_drawer-condition_sets-save_condition_button-already_saved`
																				: t`data_collection-deployment_editor_rule_drawer-condition_sets-save_condition_button-save`
																		}
																		onClick={e => {
																			e.stopPropagation();
																			setConditionToSave(conditionSet);
																		}}
																	/>
																	<ActionIconButton
																		action="remove_list"
																		onClick={() => {
																			removeCondition({
																				conditionIndex,
																			});
																			enqueueSnackbar(
																				t`data_collection-deployment_editor_rule_drawer-condition_sets-remove_condition_button-snackbar`
																			);
																		}}
																		tooltip={t`data_collection-deployment_editor_rule_drawer-condition_sets-remove_condition_button-tooltip`}
																	/>
																</>
															}
															key={selectedRule.id + conditionIndex}
															disablePadding
															expanded={
																!Boolean(
																	sidebarState.collapsed[
																		`${selectedRule.id + conditionIndex}`
																	]
																)
															}
															onChange={(e, expanded) => {
																setSidebarState(draft => {
																	if (expanded) {
																		delete draft.collapsed[
																			`${selectedRule.id + conditionIndex}`
																		];
																	} else {
																		draft.collapsed[
																			`${selectedRule.id + conditionIndex}`
																		] = true;
																	}
																});
															}}
															data-onboarding="condition-card"
														>
															<EditorOptionList
																startDivider
																options={
																	editorType === 'sdk'
																		? [
																				{
																					custom: (
																						<EditorListRadioGroup
																							title={t`data_collection-deployment_editor_rule_drawer-mobile-trigger-title`}
																							grow
																							row={false}
																							labelPlacement="right"
																							value={conditionSet.trigger}
																							onChange={(e, value) =>
																								setOption('trigger', value)
																							}
																							options={[
																								{
																									label: (
																										<Typography
																											variant="body2"
																											mt={1}
																											mb={1}
																										>
																											{t`data_collection-deployment_editor_rule_drawer-mobile-trigger-passive-primary`}
																											<Typography
																												variant="body2"
																												display="block"
																												color="textSecondary"
																											>
																												{t`data_collection-deployment_editor_rule_drawer-mobile-trigger-passive-secondary`}
																											</Typography>
																										</Typography>
																									),
																									value: 'passive',
																									icon: (
																										<DeploymentIcon value="passive" />
																									),
																								},
																								{
																									label: (
																										<Typography
																											variant="body2"
																											mt={1}
																											mb={1}
																										>
																											{t`data_collection-deployment_editor_rule_drawer-mobile-trigger-proactive-primary`}
																											<Typography
																												variant="body2"
																												display="block"
																												color="textSecondary"
																											>
																												{t`data_collection-deployment_editor_rule_drawer-mobile-trigger-proactive-secondary`}
																											</Typography>
																										</Typography>
																									),
																									value: 'proactive',
																									icon: (
																										<DeploymentIcon value="proactive" />
																									),
																								},
																							]}
																						/>
																					),
																					divider: true,
																				},
																				{
																					custom: (
																						<>
																							<ListItemIcon>
																								<DeploymentIcon type="events" />
																							</ListItemIcon>
																							<ListItemText
																								primary={t`data_collection-deployment_editor_rule_drawer-mobile-events-primary`}
																							/>
																						</>
																					),
																					divider: false,
																				},
																				{
																					custom: (
																						<Box width="100%">
																							{conditionSet.mobile.events.map(
																								(event, eventIndex) => (
																									<Grid
																										container
																										key={'event' + eventIndex}
																									>
																										<Grid
																											item
																											xs
																										>
																											<TextField
																												fullWidth
																												placeholder={t`data_collection-deployment_editor_rule_drawer-mobile-events-placeholder`}
																												value={event}
																												onChange={e =>
																													setMobileOption(
																														'events',
																														e.target.value,
																														eventIndex
																													)
																												}
																											/>
																										</Grid>
																										<Grid item>
																											<ActionIconButton
																												action="remove_list"
																												onClick={() =>
																													removeArrayOption(
																														'mobile',
																														eventIndex,
																														'events'
																													)
																												}
																											/>
																										</Grid>
																									</Grid>
																								)
																							)}
																							<Box
																								clone
																								mt={1}
																							>
																								<ButtonAddList
																									label={
																										<Trans i18nKey="data_collection-deployment_editor_rule_drawer-mobile-events-add" />
																									}
																									onClick={() =>
																										addArrayOption('mobile', '', 'events')
																									}
																								/>
																							</Box>
																						</Box>
																					),
																					divider: true,
																				},

																				{
																					custom: (
																						<>
																							<ListItemIcon>
																								<DeploymentIcon type="session" />
																							</ListItemIcon>
																							<ListItemText
																								primary={
																									<>
																										<Trans
																											i18nKey="data_collection-deployment_editor_rule_drawer-session"
																											components={{
																												text: (
																													<Typography
																														display="inline"
																														className={
																															classes.alignTextWithInput
																														}
																													/>
																												),
																												textfield: (
																													<TextField
																														className={`${classes.inputInText} ${classes.numberInput}`}
																														value={conditionSet.session}
																														type="number"
																														inputProps={{ min: 0 }}
																														onChange={e =>
																															setOption(
																																'session',
																																e.target.value
																															)
																														}
																													/>
																												),
																											}}
																										/>
																										<Box
																											display="inline-flex"
																											ml={1}
																											position="absolute"
																										>
																											<Tooltip
																												title={
																													<Trans
																														i18nKey="data_collection-deployment_editor_rule_drawer-sdk-session-tooltip"
																														components={{
																															ul: (
																																<ul
																																	className={
																																		classes.sessionTooltipList
																																	}
																																/>
																															),
																															li: <li />,
																														}}
																													/>
																												}
																											>
																												<InfoIcon color="action" />
																											</Tooltip>
																										</Box>
																									</>
																								}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-mobile-os-title`,
																					icon: <DeploymentIcon type="target" />,
																					onClick: () =>
																						setMobileOption(
																							'target',
																							conditionSet.mobile?.target?.length === 0
																								? [{ os: '', version: [''] }]
																								: []
																						),
																					action: (
																						<Switch
																							checked={
																								conditionSet.mobile?.target?.length > 0
																							}
																						/>
																					),
																					divider:
																						conditionSet.mobile?.target?.length === 0,
																					lessenTextOpacity:
																						conditionSet.mobile?.target?.length === 0,
																				},
																				{
																					show: conditionSet.mobile?.target?.length > 0,
																					custom: (
																						<Box width="100%">
																							{conditionSet.mobile?.target?.map(
																								(target, targetIndex) => (
																									<Grid
																										container
																										key={'target' + targetIndex}
																										spacing={1}
																									>
																										<Grid
																											item
																											xs
																										>
																											<TextField
																												select
																												fullWidth
																												value={target.os}
																												onChange={e =>
																													setNestedMobileOption({
																														condition: 'target',
																														nested: targetIndex,
																														nested2: 'os',
																														value: e.target.value,
																													})
																												}
																												SelectProps={{
																													displayEmpty: true,
																												}}
																											>
																												<MenuItem
																													value=""
																													disabled
																												>
																													{t`data_collection-deployment_editor_rule_drawer-mobile-os-select-placeholder`}
																												</MenuItem>
																												<MenuItem value="ios">
																													iOS
																												</MenuItem>
																												<MenuItem value="android">
																													Android
																												</MenuItem>
																											</TextField>
																										</Grid>
																										<Grid
																											item
																											xs
																										>
																											<TextField
																												fullWidth
																												value={(
																													target.version || []
																												).join(',')}
																												onChange={e =>
																													setNestedMobileOption({
																														condition: 'target',
																														nested: targetIndex,
																														nested2: 'version',
																														value:
																															e.currentTarget.value.split(
																																','
																															),
																													})
																												}
																												placeholder={t`data_collection-deployment_editor_rule_drawer-mobile-os-version-placeholder`}
																											/>
																										</Grid>
																										<Grid item>
																											<ActionIconButton
																												action="remove_list"
																												onClick={() =>
																													removeArrayOption(
																														'mobile',
																														targetIndex,
																														'target'
																													)
																												}
																											/>
																										</Grid>
																									</Grid>
																								)
																							)}
																							<Box
																								clone
																								mt={1}
																							>
																								<ButtonAddList
																									label={t`data_collection-deployment_editor_rule_drawer-mobile-os-add`}
																									onClick={() =>
																										addArrayOption(
																											'mobile',
																											{ os: '', version: [''] },
																											'target'
																										)
																									}
																								/>
																							</Box>
																						</Box>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-percentage-primary`,
																					icon: <DeploymentIcon type="percentage" />,
																					onClick: () =>
																						setOption(
																							'percentage',
																							conditionSet.percentage === null
																								? 100
																								: null
																						),
																					action: (
																						<Switch
																							checked={conditionSet.percentage !== null}
																						/>
																					),
																					divider: conditionSet.percentage === null,
																					lessenTextOpacity:
																						conditionSet.percentage === null,
																				},
																				{
																					show: conditionSet.percentage !== null,
																					custom: (
																						<Trans
																							i18nKey="data_collection-deployment_editor_rule_drawer-percentage-action"
																							components={{
																								text: (
																									<Typography
																										variant="body1"
																										display="inline"
																										className={classes.alignTextWithInput}
																									/>
																								),
																								textfield: (
																									<TextField
																										className={`${classes.inputInText} ${classes.numberInput}`}
																										value={conditionSet.percentage || ''}
																										type="number"
																										inputProps={{ min: 0, max: 100 }}
																										onChange={e =>
																											setOption(
																												'percentage',
																												e.target.value
																											)
																										}
																									/>
																								),
																							}}
																						/>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-clock-primary`,
																					icon: <DeploymentIcon type="clock" />,
																					onClick: () =>
																						setOption(
																							'clock',
																							conditionSet.clock?.hasOwnProperty('time')
																								? {}
																								: innerConditionBase('clock')
																						),
																					action: (
																						<Switch
																							checked={conditionSet.clock?.hasOwnProperty(
																								'time'
																							)}
																						/>
																					),
																					divider:
																						!conditionSet.clock?.hasOwnProperty('time'),
																					lessenTextOpacity:
																						!conditionSet.clock?.hasOwnProperty('time'),
																				},
																				{
																					show: conditionSet.clock?.hasOwnProperty(
																						'time'
																					),
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey={
																									conditionSet.clock?.operator ===
																									'between'
																										? `data_collection-deployment_editor_rule_drawer-clock-action-between`
																										: `data_collection-deployment_editor_rule_drawer-clock-action-default`
																								}
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									operator: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={conditionSet.clock?.operator}
																											onChange={e =>
																												setNestedOption(
																													'clock',
																													e.target.value,
																													'operator'
																												)
																											}
																										>
																											{dateTimeOperators.map(
																												dtOperator => {
																													return (
																														<MenuItem
																															key={dtOperator.value}
																															value={dtOperator.value}
																														>
																															{t(dtOperator.label)}
																														</MenuItem>
																													);
																												}
																											)}
																										</TextField>
																									),
																									clock1picker: (
																										<KeyboardTimePicker
																											ampm={false}
																											variant="inline"
																											className={`${classes.inputInText}`}
																											value={
																												new Date([
																													'1970',
																													'1',
																													'1',
																													conditionSet.clock?.time ||
																														'12:00',
																												])
																											}
																											onChange={date => {
																												if (isValid(date)) {
																													setNestedOption(
																														'clock',
																														format(date, 'HH:mm'),
																														'time'
																													);
																												}
																											}}
																										/>
																									),
																									clock2picker: (
																										<KeyboardTimePicker
																											ampm={false}
																											variant="inline"
																											className={`${classes.inputInText}`}
																											value={
																												new Date([
																													'1970',
																													'1',
																													'1',
																													conditionSet.clock?.time2 ||
																														'23:59',
																												])
																											}
																											onChange={date => {
																												if (isValid(date)) {
																													setNestedOption(
																														'clock',
																														format(date, 'HH:mm'),
																														'time2'
																													);
																												}
																											}}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},
																		  ]
																		: [
																				{
																					custom: (
																						<EditorListRadioGroup
																							title={t`data_collection-deployment_editor_rule_drawer-trigger-title`}
																							grow
																							row={false}
																							labelPlacement="right"
																							value={conditionSet.trigger}
																							onChange={(e, value) =>
																								setOption('trigger', value)
																							}
																							options={
																								surveyInRule?.modal === 0
																									? [
																											{
																												label: (
																													<Typography
																														variant="body2"
																														mt={1}
																														mb={1}
																													>
																														{t`data_collection-deployment_editor_rule_drawer-trigger-embed-passive-primary`}
																														<Typography
																															variant="body2"
																															display="block"
																															color="textSecondary"
																														>
																															{t`data_collection-deployment_editor_rule_drawer-trigger-embed-passive-secondary`}
																														</Typography>
																													</Typography>
																												),
																												value: 'passive',
																												icon: (
																													<DeploymentIcon value="passive" />
																												),
																											},
																											{
																												label: (
																													<Typography
																														variant="body2"
																														mt={1}
																														mb={1}
																													>
																														{t`data_collection-deployment_editor_rule_drawer-trigger-embed-proactive-primary`}
																														<Typography
																															variant="body2"
																															display="block"
																															color="textSecondary"
																														>
																															{t`data_collection-deployment_editor_rule_drawer-trigger-embed-proactive-secondary`}
																														</Typography>
																													</Typography>
																												),
																												value: 'proactive',
																												icon: (
																													<DeploymentIcon value="proactive" />
																												),
																											},
																									  ]
																									: [
																											{
																												label: (
																													<Typography
																														variant="body2"
																														mt={1}
																														mb={1}
																													>
																														{t`data_collection-deployment_editor_rule_drawer-trigger-passive-primary`}
																														<Typography
																															variant="body2"
																															display="block"
																															color="textSecondary"
																														>
																															{t`data_collection-deployment_editor_rule_drawer-trigger-passive-secondary`}
																														</Typography>
																													</Typography>
																												),
																												value: 'passive',
																												icon: (
																													<DeploymentIcon value="passive" />
																												),
																											},
																											{
																												label: (
																													<Typography
																														variant="body2"
																														mt={1}
																														mb={1}
																													>
																														{t`data_collection-deployment_editor_rule_drawer-trigger-proactive-primary`}
																														<Typography
																															variant="body2"
																															display="block"
																															color="textSecondary"
																														>
																															{t`data_collection-deployment_editor_rule_drawer-trigger-proactive-secondary`}
																														</Typography>
																													</Typography>
																												),
																												value: 'proactive',
																												icon: (
																													<DeploymentIcon value="proactive" />
																												),
																											},
																											{
																												label: (
																													<Typography
																														variant="body2"
																														mt={1}
																														mb={1}
																													>
																														{t`data_collection-deployment_editor_rule_drawer-trigger-exit-primary`}
																														<Typography
																															variant="body2"
																															display="block"
																															color="textSecondary"
																														>
																															{t`data_collection-deployment_editor_rule_drawer-trigger-exit-secondary`}
																														</Typography>
																													</Typography>
																												),
																												value: 'exit',
																												icon: (
																													<DeploymentIcon value="exit" />
																												),
																											},
																									  ]
																							}
																						/>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-pause-primary`,
																					icon: <DeploymentIcon type="pause" />,
																					secondary: t`data_collection-deployment_editor_rule_drawer-pause-secondary`,
																					onClick: () =>
																						setOption('pause', !conditionSet.pause),
																					action: (
																						<Switch
																							checked={Boolean(conditionSet.pause)}
																						/>
																					),
																					divider: true,
																					lessenTextOpacity: !Boolean(conditionSet.pause),
																				},
																				{
																					custom: (
																						<>
																							<ListItemIcon>
																								<DeploymentIcon type="session" />
																							</ListItemIcon>
																							<ListItemText
																								primary={
																									<>
																										<Trans
																											i18nKey="data_collection-deployment_editor_rule_drawer-session"
																											components={{
																												text: (
																													<Typography
																														variant="body1"
																														display="inline"
																														className={
																															classes.alignTextWithInput
																														}
																													/>
																												),
																												textfield: (
																													<TextField
																														className={`${classes.inputInText} ${classes.numberInput}`}
																														value={conditionSet.session}
																														type="number"
																														inputProps={{ min: 0 }}
																														onChange={e => {
																															setOption(
																																'session',
																																e.target.value
																															);
																														}}
																													/>
																												),
																											}}
																										/>
																										<Box
																											ml={1}
																											display="inline-flex"
																											alignItems="center"
																											position="absolute"
																										>
																											<Tooltip
																												title={
																													<Trans
																														i18nKey="data_collection-deployment_editor_rule_drawer-session-tooltip"
																														components={{
																															ul: (
																																<ul
																																	className={
																																		classes.sessionTooltipList
																																	}
																																/>
																															),
																															li: <li />,
																														}}
																													/>
																												}
																											>
																												<InfoIcon color="action" />
																											</Tooltip>
																										</Box>
																									</>
																								}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-percentage-primary`,
																					icon: <DeploymentIcon type="percentage" />,
																					onClick: () =>
																						setOption(
																							'percentage',
																							conditionSet.percentage === null
																								? 100
																								: null
																						),
																					action: (
																						<Switch
																							checked={conditionSet.percentage !== null}
																						/>
																					),
																					divider: conditionSet.percentage === null,
																					lessenTextOpacity:
																						conditionSet.percentage === null,
																				},
																				{
																					show: conditionSet.percentage !== null,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-percentage-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									textfield: (
																										<TextField
																											className={`${classes.inputInText} ${classes.numberInput}`}
																											value={
																												conditionSet.percentage || ''
																											}
																											type="number"
																											inputProps={{ min: 0, max: 100 }}
																											onChange={e =>
																												setOption(
																													'percentage',
																													e.target.value
																												)
																											}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},
																				...['location', 'referrer'].flatMap(
																					conditionName => {
																						return [
																							{
																								primary: t(
																									`data_collection-deployment_editor_rule_drawer-${conditionName}-primary`
																								),
																								icon: (
																									<DeploymentIcon type={conditionName} />
																								),
																								secondary:
																									conditionSet[conditionName].length > 0
																										? t(
																												`data_collection-deployment_editor_rule_drawer-${conditionName}-secondary`
																										  )
																										: '',
																								onClick: () =>
																									setOption(
																										conditionName,
																										conditionSet[conditionName].length ===
																											0
																											? [
																													[
																														innerConditionBase(
																															conditionName
																														),
																													],
																											  ]
																											: []
																									),
																								action: (
																									<Switch
																										checked={
																											conditionSet[conditionName].length >
																											0
																										}
																									/>
																								),
																								divider:
																									conditionSet[conditionName].length ===
																									0,
																								lessenTextOpacity:
																									conditionSet[conditionName].length ===
																									0,
																							},
																							{
																								show:
																									conditionSet[conditionName].length > 0,
																								custom: (
																									<>
																										<EditorNestedOrAndArrayConditions
																											conditionName={conditionName}
																											orArray={
																												conditionSet[conditionName]
																											}
																											onAddOr={({ conditionName }) =>
																												addArrayOption(conditionName, [
																													innerConditionBase(
																														conditionName
																													),
																												])
																											}
																											onAddAnd={({
																												conditionName,
																												orIndex,
																											}) =>
																												addArrayOption(
																													conditionName,
																													innerConditionBase(
																														conditionName
																													),
																													orIndex
																												)
																											}
																											onRemoveAnd={({
																												andArr,
																												orIndex,
																												andIndex,
																												conditionName,
																											}) =>
																												andArr.length === 1
																													? removeArrayOption(
																															conditionName,
																															orIndex
																													  )
																													: removeArrayOption(
																															conditionName,
																															andIndex,
																															orIndex
																													  )
																											}
																										>
																											{({
																												conditionObj,
																												andIndex,
																												andArr,
																												orIndex,
																											}) => (
																												<Grid
																													container
																													spacing={2}
																													alignItems="center"
																												>
																													<Grid
																														item
																														xs={3}
																													>
																														<TextField
																															select
																															fullWidth
																															value={
																																conditionSet[
																																	conditionName
																																][orIndex][andIndex]
																																	.operator
																															}
																															onChange={e =>
																																setNestedOption(
																																	conditionName,
																																	e.target.value,
																																	orIndex,
																																	andIndex,
																																	'operator'
																																)
																															}
																														>
																															{operators(
																																conditionName
																															).map(operatorObj => (
																																<MenuItem
																																	key={operatorObj.value}
																																	value={
																																		operatorObj.value
																																	}
																																>
																																	{t(
																																		`data_collection-deployment_editor_rule_drawer-operators-url-${operatorObj.value}`
																																	)}
																																</MenuItem>
																															))}
																														</TextField>
																													</Grid>
																													<Grid
																														item
																														xs={7}
																													>
																														<TextField
																															fullWidth
																															placeholder="yourwebsite.com/path"
																															value={
																																conditionSet[
																																	conditionName
																																][orIndex][andIndex].value
																															}
																															onChange={e => {
																																const strippedValue =
																																	e.target.value.replace(
																																		/^(?:https?:\/\/)?(?:www\.)?/i,
																																		''
																																	);
																																setNestedOption(
																																	conditionName,
																																	strippedValue,
																																	orIndex,
																																	andIndex,
																																	'value'
																																);
																															}}
																														/>
																													</Grid>

																													<Grid
																														item
																														xs={2}
																													>
																														<SwitchWithLabel
																															label={t(
																																`data_collection-deployment_editor_rule_drawer-${conditionName}-regex-label`
																															)}
																															tooltip={t(
																																`data_collection-deployment_editor_rule_drawer-${conditionName}-regex-tooltip`
																															)}
																															checked={
																																conditionSet[
																																	conditionName
																																][orIndex][andIndex].regex
																															}
																															onChange={e =>
																																setNestedOption(
																																	conditionName,
																																	e.target.checked,
																																	orIndex,
																																	andIndex,
																																	'regex'
																																)
																															}
																														/>
																													</Grid>
																												</Grid>
																											)}
																										</EditorNestedOrAndArrayConditions>
																									</>
																								),

																								divider: true,
																							},
																						];
																					}
																				),

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-time-primary`,
																					icon: <DeploymentIcon type="time" />,
																					onClick: () =>
																						setOption(
																							'time',
																							conditionSet.time === null ? 0 : null
																						),
																					action: (
																						<Switch
																							checked={conditionSet.time !== null}
																						/>
																					),
																					divider: conditionSet.time === null,
																					lessenTextOpacity: conditionSet.time === null,
																				},
																				{
																					show: conditionSet.time !== null,
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-time-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									textfield: (
																										<TextField
																											className={`${classes.inputInText} ${classes.numberInput}`}
																											value={conditionSet.time || 0}
																											type="number"
																											inputProps={{ min: 0 }}
																											onChange={e =>
																												setOption('time', e.target.value)
																											}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-time-after-valid-primary`,
																					icon: <DeploymentIcon type="time" />,
																					onClick: () =>
																						setOption(
																							'time_after_valid',
																							typeof conditionSet.time_after_valid ===
																								'undefined' ||
																								conditionSet.time_after_valid === null
																								? 0
																								: null
																						),
																					action: (
																						<Switch
																							checked={
																								typeof conditionSet.time_after_valid !==
																									'undefined' &&
																								conditionSet.time_after_valid !== null
																							}
																						/>
																					),
																					divider:
																						typeof conditionSet.time_after_valid ===
																							'undefined' ||
																						conditionSet.time_after_valid === null,
																					lessenTextOpacity:
																						typeof conditionSet.time_after_valid ===
																							'undefined' ||
																						conditionSet.time_after_valid === null,
																				},
																				{
																					show:
																						typeof conditionSet.time_after_valid !==
																							'undefined' &&
																						conditionSet.time_after_valid !== null,
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-time-after-valid-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									textfield: (
																										<TextField
																											className={`${classes.inputInText} ${classes.numberInput}`}
																											value={
																												conditionSet.time_after_valid || 0
																											}
																											type="number"
																											inputProps={{ min: 0 }}
																											onChange={e =>
																												setOption(
																													'time_after_valid',
																													e.target.value
																												)
																											}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-time_in_session-primary`,
																					icon: <DeploymentIcon type="time_in_session" />,
																					onClick: () =>
																						setOption(
																							'time_in_session',
																							conditionSet.time_in_session === null
																								? 0
																								: null
																						),
																					action: (
																						<Switch
																							checked={
																								conditionSet.time_in_session !== null
																							}
																						/>
																					),
																					divider: conditionSet.time_in_session === null,
																					lessenTextOpacity:
																						conditionSet.time_in_session === null,
																				},
																				{
																					show: conditionSet.time_in_session !== null,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-time_in_session-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									textfield: (
																										<TextField
																											className={`${classes.inputInText} ${classes.numberInput}`}
																											value={
																												conditionSet.time_in_session || 0
																											}
																											type="number"
																											inputProps={{ min: 0 }}
																											onChange={e =>
																												setOption(
																													'time_in_session',
																													e.target.value
																												)
																											}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-clock-primary`,
																					icon: <DeploymentIcon type="clock" />,
																					onClick: () =>
																						setOption(
																							'clock',
																							conditionSet.clock?.hasOwnProperty('time')
																								? {}
																								: innerConditionBase('clock')
																						),
																					action: (
																						<Switch
																							checked={conditionSet.clock?.hasOwnProperty(
																								'time'
																							)}
																						/>
																					),
																					divider:
																						!conditionSet.clock?.hasOwnProperty('time'),
																					lessenTextOpacity:
																						!conditionSet.clock?.hasOwnProperty('time'),
																				},
																				{
																					show: conditionSet.clock?.hasOwnProperty(
																						'time'
																					),
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey={
																									conditionSet.clock?.operator ===
																									'between'
																										? `data_collection-deployment_editor_rule_drawer-clock-action-between`
																										: `data_collection-deployment_editor_rule_drawer-clock-action-default`
																								}
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									operator: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={conditionSet.clock?.operator}
																											onChange={e =>
																												setNestedOption(
																													'clock',
																													e.target.value,
																													'operator'
																												)
																											}
																										>
																											{dateTimeOperators.map(
																												dtOperator => {
																													return (
																														<MenuItem
																															key={dtOperator.value}
																															value={dtOperator.value}
																														>
																															{t(dtOperator.label)}
																														</MenuItem>
																													);
																												}
																											)}
																										</TextField>
																									),
																									clock1picker: (
																										<KeyboardTimePicker
																											ampm={false}
																											variant="inline"
																											className={`${classes.inputInText}`}
																											value={
																												new Date([
																													'1970',
																													'1',
																													'1',
																													conditionSet.clock?.time ||
																														'12:00',
																												])
																											}
																											onChange={date => {
																												if (isValid(date)) {
																													setNestedOption(
																														'clock',
																														format(date, 'HH:mm'),
																														'time'
																													);
																												}
																											}}
																										/>
																									),
																									clock2picker: (
																										<KeyboardTimePicker
																											ampm={false}
																											variant="inline"
																											className={`${classes.inputInText}`}
																											value={
																												new Date([
																													'1970',
																													'1',
																													'1',
																													conditionSet.clock?.time2 ||
																														'23:59',
																												])
																											}
																											onChange={date => {
																												if (isValid(date)) {
																													setNestedOption(
																														'clock',
																														format(date, 'HH:mm'),
																														'time2'
																													);
																												}
																											}}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-date-primary`,
																					icon: <DeploymentIcon type="date" />,
																					onClick: () =>
																						setOption(
																							'date',
																							conditionSet.date?.hasOwnProperty('date')
																								? {}
																								: innerConditionBase('date')
																						),
																					action: (
																						<Switch
																							checked={conditionSet.date?.hasOwnProperty(
																								'date'
																							)}
																						/>
																					),
																					divider:
																						!conditionSet.date?.hasOwnProperty('date'),
																					lessenTextOpacity:
																						!conditionSet.date?.hasOwnProperty('date'),
																				},
																				{
																					show: conditionSet.date?.hasOwnProperty('date'),
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey={
																									conditionSet.date?.operator ===
																									'between'
																										? `data_collection-deployment_editor_rule_drawer-date-action-between`
																										: `data_collection-deployment_editor_rule_drawer-date-action-default`
																								}
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									operator: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={conditionSet.date?.operator}
																											onChange={e =>
																												setNestedOption(
																													'date',
																													e.target.value,
																													'operator'
																												)
																											}
																										>
																											{dateTimeOperators.map(
																												dtOperator => {
																													return (
																														<MenuItem
																															key={dtOperator.value}
																															value={dtOperator.value}
																														>
																															{t(dtOperator.label)}
																														</MenuItem>
																													);
																												}
																											)}
																										</TextField>
																									),
																									date1picker: (
																										<KeyboardDatePicker
																											ampm={false}
																											variant="inline"
																											className={`${classes.inputInText} ${classes.datePickerInput}`}
																											value={
																												conditionSet.date?.date
																													? new Date(
																															conditionSet.date?.date
																																.split('/')
																																.reverse()
																													  )
																													: new Date()
																											}
																											onChange={date => {
																												if (isValid(date)) {
																													setNestedOption(
																														'date',
																														format(date, 'dd/MM/yyyy'),
																														'date'
																													);
																												}
																											}}
																											format="dd/MM/yyyy"
																										/>
																									),
																									date2picker: (
																										<KeyboardDatePicker
																											ampm={false}
																											variant="inline"
																											className={`${classes.inputInText} ${classes.datePickerInput}`}
																											value={
																												conditionSet.date?.date2
																													? new Date(
																															conditionSet.date?.date2
																																.split('/')
																																.reverse()
																													  )
																													: new Date()
																											}
																											onChange={date => {
																												if (isValid(date)) {
																													setNestedOption(
																														'date',
																														format(date, 'dd/MM/yyyy'),
																														'date2'
																													);
																												}
																											}}
																											format="dd/MM/yyyy"
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},
																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-number_of_pages-primary`,
																					icon: <DeploymentIcon type="number_of_pages" />,
																					onClick: () =>
																						setOption(
																							'number_of_pages',
																							conditionSet.number_of_pages.hasOwnProperty(
																								'amount'
																							)
																								? {}
																								: innerConditionBase('number_of_pages')
																						),
																					action: (
																						<Switch
																							checked={conditionSet.number_of_pages.hasOwnProperty(
																								'amount'
																							)}
																						/>
																					),
																					divider:
																						!conditionSet.number_of_pages.hasOwnProperty(
																							'amount'
																						),
																					lessenTextOpacity:
																						!conditionSet.number_of_pages.hasOwnProperty(
																							'amount'
																						),
																				},
																				{
																					show: conditionSet.number_of_pages.hasOwnProperty(
																						'amount'
																					),
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-number_of_pages-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									operator: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={
																												conditionSet.number_of_pages.check
																											}
																											onChange={e =>
																												setNestedOption(
																													'number_of_pages',
																													e.target.value,
																													'check'
																												)
																											}
																										>
																											{operators('number_of_pages').map(
																												operator => (
																													<MenuItem
																														key={
																															'numpages' + operator.value
																														}
																														value={operator.value}
																													>
																														{operator.label}
																													</MenuItem>
																												)
																											)}
																										</TextField>
																									),
																									textfield: (
																										<TextField
																											className={`${classes.inputInText} ${classes.numberInput}`}
																											value={
																												conditionSet.number_of_pages
																													.amount || 0
																											}
																											type="number"
																											inputProps={{ min: 0 }}
																											onChange={e =>
																												setNestedOption(
																													'number_of_pages',
																													e.target.value,
																													'amount'
																												)
																											}
																										/>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},
																				//start type (new/returning)
																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-type-primary`,
																					icon: <DeploymentIcon type="type" />,
																					onClick: () =>
																						setOption(
																							'type',
																							conditionSet.type ? null : 'new'
																						),
																					action: (
																						<Switch
																							checked={conditionSet.type !== null}
																						/>
																					),
																					divider: conditionSet.type === null,
																					lessenTextOpacity: conditionSet.type === null,
																				},
																				{
																					show: conditionSet.type !== null,
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-type-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									operator: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={conditionSet.type}
																											onChange={e =>
																												setOption('type', e.target.value)
																											}
																										>
																											{operators('type').map(operator => (
																												<MenuItem
																													key={'type' + operator.value}
																													value={operator.value}
																												>
																													{t(
																														`data_collection-deployment_editor_rule_drawer-type-operator-${operator.value}`
																													)}
																												</MenuItem>
																											))}
																										</TextField>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},
																				//end new/returning visitors

																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-devices-primary`,
																					icon: <DeploymentIcon type="devices" />,
																					onClick: () =>
																						setOption(
																							'devices',
																							conditionSet.devices.length > 0
																								? []
																								: ['mobile']
																						),
																					action: (
																						<Switch
																							checked={conditionSet.devices.length > 0}
																						/>
																					),
																					divider: conditionSet.devices.length === 0,
																					lessenTextOpacity:
																						conditionSet.devices.length === 0,
																				},
																				{
																					show: conditionSet.devices.length > 0,
																					custom: (
																						<>
																							<Box width="100%">
																								<Typography
																									variant="body1"
																									display="block"
																								>{t`data_collection-deployment_editor_rule_drawer-devices-secondary`}</Typography>
																								<FormControl>
																									<FormGroup>
																										{[
																											{
																												label: t`data_collection-deployment_editor_rule_drawer-devices-mobile`,
																												value: 'mobile',
																											},
																											{
																												label: t`data_collection-deployment_editor_rule_drawer-devices-tablet`,
																												value: 'tablet',
																											},
																											{
																												label: t`data_collection-deployment_editor_rule_drawer-devices-desktop`,
																												value: 'desktop',
																											},
																										].map(deviceType => (
																											<FormControlLabel
																												key={deviceType.value}
																												control={
																													<Checkbox
																														checked={
																															conditionSet.devices.indexOf(
																																deviceType.value
																															) > -1
																														}
																														onChange={e =>
																															e.target.checked
																																? addArrayOption(
																																		'devices',
																																		deviceType.value
																																  )
																																: removeArrayOption(
																																		'devices',
																																		conditionSet.devices.indexOf(
																																			deviceType.value
																																		)
																																  )
																														}
																														value={deviceType.value}
																													/>
																												}
																												label={deviceType.label}
																											/>
																										))}
																									</FormGroup>
																								</FormControl>
																							</Box>
																						</>
																					),
																					divider: true,
																				},
																				{
																					primary: t`data_collection-deployment_editor_rule_drawer-scrollpos-primary`,
																					icon: <DeploymentIcon type="scrollpos" />,
																					onClick: () =>
																						setOption(
																							'scrollpos',
																							Object.keys(conditionSet.scrollpos).length >
																								0
																								? {}
																								: innerConditionBase('scrollpos')
																						),
																					action: (
																						<Switch
																							checked={
																								Object.keys(conditionSet.scrollpos)
																									.length > 0
																							}
																						/>
																					),
																					divider:
																						Object.keys(conditionSet.scrollpos).length ===
																						0,
																					lessenTextOpacity:
																						Object.keys(conditionSet.scrollpos).length ===
																						0,
																				},
																				{
																					show:
																						Object.keys(conditionSet.scrollpos).length >
																						0,
																					wrap: true,
																					custom: (
																						<>
																							<Trans
																								i18nKey="data_collection-deployment_editor_rule_drawer-scrollpos-action"
																								components={{
																									text: (
																										<Typography
																											variant="body1"
																											display="inline"
																											className={
																												classes.alignTextWithInput
																											}
																										/>
																									),
																									value: (
																										<TextField
																											className={`${classes.inputInText} ${classes.numberInput}`}
																											value={
																												conditionSet.scrollpos.px
																													? Number(
																															String(
																																conditionSet.scrollpos.px
																															).replace('%', '')
																													  )
																													: 0
																											}
																											type="number"
																											inputProps={{ min: 0 }}
																											onChange={e =>
																												setNestedOption(
																													'scrollpos',
																													e.target.value,
																													'px'
																												)
																											}
																										/>
																									),
																									valueType: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={
																												conditionSet.scrollpos.px &&
																												conditionSet.scrollpos.px.indexOf(
																													'%'
																												) > -1
																													? '%'
																													: 'px'
																											}
																											onChange={e =>
																												setNestedOption(
																													'scrollpos',
																													e.target.value === '%'
																														? String(
																																conditionSet.scrollpos.px
																														  ).replace('%', '') + '%'
																														: String(
																																conditionSet.scrollpos.px
																														  ).replace('%', ''),
																													'px'
																												)
																											}
																										>
																											<MenuItem value="px">{t`data_collection-deployment_editor_rule_drawer-scrollpos-pixels`}</MenuItem>
																											<MenuItem value="%">{t`data_collection-deployment_editor_rule_drawer-scrollpos-percentage`}</MenuItem>
																										</TextField>
																									),
																									operator: (
																										<TextField
																											select
																											className={`${classes.inputInText} ${classes.selectInput}`}
																											value={conditionSet.scrollpos.from}
																											onChange={e =>
																												setNestedOption(
																													'scrollpos',
																													e.target.value,
																													'from'
																												)
																											}
																										>
																											{operators('scrollpos').map(
																												operator => (
																													<MenuItem
																														key={operator.value}
																														value={operator.value}
																													>
																														{t(
																															`data_collection-deployment_editor_rule_drawer-scrollpos-${operator.label}`
																														)}
																													</MenuItem>
																												)
																											)}
																										</TextField>
																									),
																								}}
																							/>
																						</>
																					),
																					divider: true,
																				},

																				...[
																					{
																						primary: t`data_collection-deployment_editor_rule_drawer-cookie-primary`,
																						icon: <DeploymentIcon type="cookie" />,
																						hintText: '',
																						conditionName: 'cookie',
																						placeholderKey: t`data_collection-deployment_editor_rule_drawer-cookie-placeholder_key`,
																						placeholderValue: t`data_collection-deployment_editor_rule_drawer-cookie-placeholder_value`,
																					},
																					{
																						primary: t`data_collection-deployment_editor_rule_drawer-js-primary`,
																						icon: <DeploymentIcon type="js" />,
																						hintText: '',
																						conditionName: 'js',
																						placeholderKey: t`data_collection-deployment_editor_rule_drawer-js-placeholder_key`,
																						placeholderValue: t`data_collection-deployment_editor_rule_drawer-js-placeholder_value`,
																					},
																					{
																						primary: t`data_collection-deployment_editor_rule_drawer-css_selector-primary`,
																						icon: <DeploymentIcon type="css_selector" />,
																						hintText: '',
																						conditionName: 'css_selector',
																						placeholderKey: t`data_collection-deployment_editor_rule_drawer-css_selector-placeholder_key`,
																						placeholderValue: t`data_collection-deployment_editor_rule_drawer-css_selector-placeholder_value`,
																					},
																				].flatMap(obj => {
																					return [
																						{
																							primary: obj.primary,
																							icon: obj.icon,
																							onClick: () =>
																								setOption(
																									obj.conditionName,
																									conditionSet[obj.conditionName]
																										.length === 0
																										? [
																												[
																													innerConditionBase(
																														obj.conditionName
																													),
																												],
																										  ]
																										: []
																								),
																							action: (
																								<Switch
																									checked={
																										conditionSet[obj.conditionName]
																											?.length > 0 ?? false
																									}
																								/>
																							),
																							divider:
																								conditionSet[obj.conditionName].length ===
																								0,
																							lessenTextOpacity:
																								conditionSet[obj.conditionName].length ===
																								0,
																						},
																						{
																							show:
																								conditionSet[obj.conditionName]?.length >
																								0,
																							custom: (
																								<>
																									<EditorNestedOrAndArrayConditions
																										conditionName={obj.conditionName}
																										orArray={
																											conditionSet[obj.conditionName]
																										}
																										onAddOr={({ conditionName }) =>
																											addArrayOption(conditionName, [
																												innerConditionBase(conditionName),
																											])
																										}
																										onAddAnd={({
																											conditionName,
																											orIndex,
																										}) =>
																											addArrayOption(
																												conditionName,
																												innerConditionBase(conditionName),
																												orIndex
																											)
																										}
																										onRemoveAnd={({
																											andArr,
																											orIndex,
																											andIndex,
																											conditionName,
																										}) =>
																											andArr.length === 1
																												? removeArrayOption(
																														conditionName,
																														orIndex
																												  )
																												: removeArrayOption(
																														conditionName,
																														andIndex,
																														orIndex
																												  )
																										}
																										hintText={obj.hintText}
																									>
																										{({
																											conditionObj,
																											andIndex,
																											andArr,
																											orIndex,
																										}) => {
																											const showInput =
																												[
																													'exists',
																													'does not exist',
																												].indexOf(
																													conditionObj.operator
																												) === -1;

																											const alignFlexEnd =
																												Array.isArray(
																													conditionObj.value
																												) &&
																												conditionObj.value.length > 2;
																											return (
																												<Grid
																													container
																													spacing={2}
																													alignItems="center"
																												>
																													<Grid
																														item
																														xs={4}
																													>
																														<ValidatedField
																															rules={
																																obj.conditionName ===
																																'css_selector'
																																	? {
																																			css_selector: true,
																																	  }
																																	: {}
																															}
																															errorMessage={t`data_collection-deployment_editor_rule_drawer-css_selector-error`}
																															fullWidth
																															showErrorsAfterBlur={
																																obj.conditionName ===
																																'css_selector'
																															}
																															placeholder={
																																obj.placeholderKey
																															}
																															value={
																																conditionSet[
																																	obj.conditionName
																																][orIndex][andIndex].name
																															}
																															onChange={e =>
																																setNestedOption(
																																	obj.conditionName,
																																	e.target.value,
																																	orIndex,
																																	andIndex,
																																	'name'
																																)
																															}
																														/>
																													</Grid>
																													<Grid
																														item
																														xs={3}
																													>
																														<TextField
																															select
																															fullWidth
																															value={
																																conditionSet[
																																	obj.conditionName
																																][orIndex][andIndex]
																																	.operator
																															}
																															onChange={e => {
																																setNestedOption(
																																	obj.conditionName,
																																	e.target.value,
																																	orIndex,
																																	andIndex,
																																	'operator'
																																);

																																const resetValue =
																																	e.target.value ===
																																	'is boolean'
																																		? 'true'
																																		: '';
																																setNestedOption(
																																	obj.conditionName,
																																	resetValue,
																																	orIndex,
																																	andIndex,
																																	'value'
																																);
																															}}
																														>
																															{operators(
																																obj.conditionName
																															).map(operatorObj => (
																																<MenuItem
																																	key={operatorObj.value}
																																	value={
																																		operatorObj.value
																																	}
																																>
																																	{operatorObj.label}
																																</MenuItem>
																															))}
																														</TextField>
																													</Grid>
																													{showInput && (
																														<Grid
																															item
																															xs={5}
																														>
																															{conditionSet[
																																obj.conditionName
																															][orIndex][andIndex]
																																.operator !==
																															'is boolean' ? (
																																<DeploymentEditorMultipleValuesAutocomplete
																																	value={
																																		conditionSet[
																																			obj.conditionName
																																		][orIndex][andIndex]
																																			.value ?? ''
																																	}
																																	onChange={(e, value) =>
																																		setNestedOption(
																																			obj.conditionName,
																																			value,
																																			orIndex,
																																			andIndex,
																																			'value'
																																		)
																																	}
																																	onAddInput={value => {
																																		setNestedOption(
																																			obj.conditionName,
																																			[
																																				...new Set([
																																					...(Array.isArray(
																																						conditionObj.value
																																					)
																																						? conditionObj.value
																																						: [
																																								conditionObj.value,
																																						  ]),
																																					...(Array.isArray(
																																						value
																																					)
																																						? value
																																						: [value]),
																																				]),
																																			],
																																			orIndex,
																																			andIndex,
																																			'value'
																																		);
																																	}}
																																/>
																															) : (
																																<TextField
																																	fullWidth
																																	placeholder={
																																		obj.placeholderValue
																																	}
																																	value={
																																		conditionSet[
																																			obj.conditionName
																																		][orIndex][andIndex]
																																			.value
																																	}
																																	onChange={e =>
																																		setNestedOption(
																																			obj.conditionName,
																																			e.target.value,
																																			orIndex,
																																			andIndex,
																																			'value'
																																		)
																																	}
																																	select={
																																		conditionSet[
																																			obj.conditionName
																																		][orIndex][andIndex]
																																			.operator ===
																																		'is boolean'
																																	}
																																>
																																	{conditionSet[
																																		obj.conditionName
																																	][orIndex][andIndex]
																																		.operator ===
																																		'is boolean' &&
																																		['true', 'false'].map(
																																			booleanObj => (
																																				<MenuItem
																																					key={booleanObj}
																																					value={
																																						booleanObj
																																					}
																																				>
																																					{booleanObj}
																																				</MenuItem>
																																			)
																																		)}
																																</TextField>
																															)}
																														</Grid>
																													)}
																												</Grid>
																											);
																										}}
																									</EditorNestedOrAndArrayConditions>
																								</>
																							),
																							divider: true,
																						},
																					];
																				}),
																				...[
																					{
																						primary: t`data_collection-deployment_editor_rule_drawer-form_events-primary`,
																						icon: <DeploymentIcon type="form_events" />,
																						conditionName: 'form_events',
																					},
																					{
																						primary: t`data_collection-deployment_editor_rule_drawer-event_listeners-primary`,
																						icon: (
																							<DeploymentIcon type="event_listeners" />
																						),
																						placeholderKey: t`data_collection-deployment_editor_rule_drawer-event_listeners-placeholder`,
																						conditionName: 'event_listeners',
																					},
																				].flatMap(obj => {
																					const hasConditionsSet =
																						conditionSet[obj.conditionName]?.length > 0;

																					return [
																						{
																							primary: obj.primary,
																							secondary: obj.secondary,
																							icon: obj.icon,
																							onClick: () => {
																								track(
																									`deployment_editor_conditions_${
																										obj.conditionName
																									}_${
																										hasConditionsSet ? 'remove' : 'add'
																									}`
																								);
																								setOption(
																									obj.conditionName,
																									hasConditionsSet
																										? []
																										: [
																												[
																													innerConditionBase(
																														obj.conditionName
																													),
																												],
																										  ]
																								);
																							},
																							action: (
																								<Switch checked={hasConditionsSet} />
																							),
																							divider: !hasConditionsSet,
																							lessenTextOpacity: !hasConditionsSet,
																						},
																						{
																							show: hasConditionsSet,
																							custom: (
																								<EditorNestedOrAndArrayConditions
																									conditionName={obj.conditionName}
																									orArray={
																										conditionSet[obj.conditionName]
																									}
																									onAddOr={({ conditionName }) =>
																										addArrayOption(conditionName, [
																											innerConditionBase(conditionName),
																										])
																									}
																									onAddAnd={({
																										conditionName,
																										orIndex,
																									}) =>
																										addArrayOption(
																											conditionName,
																											innerConditionBase(conditionName),
																											orIndex
																										)
																									}
																									onRemoveAnd={({
																										andArr,
																										orIndex,
																										andIndex,
																										conditionName,
																									}) =>
																										andArr.length === 1
																											? removeArrayOption(
																													conditionName,
																													orIndex
																											  )
																											: removeArrayOption(
																													conditionName,
																													andIndex,
																													orIndex
																											  )
																									}
																									hintText={obj.hintText}
																								>
																									{({
																										conditionObj,
																										andIndex,
																										andArr,
																										orIndex,
																									}) => {
																										return (
																											<Grid
																												container
																												spacing={2}
																												alignItems="center"
																											>
																												<Grid
																													item
																													xs={5}
																												>
																													{obj.conditionName ===
																														'form_events' && (
																														<DataSourceAutocomplete
																															value={conditionObj.formKey}
																															onChange={(e, value) => {
																																setNestedOption(
																																	obj.conditionName,
																																	value.survey_key,
																																	orIndex,
																																	andIndex,
																																	'formKey'
																																);
																															}}
																															options={
																																autocompleteSurveyOptions
																															}
																														/>
																													)}
																													{obj.conditionName ===
																														'event_listeners' && (
																														<ValidatedField
																															rules={{
																																css_selector: true,
																															}}
																															errorMessage={t`data_collection-deployment_editor_rule_drawer-css_selector-error`}
																															showErrorsAfterBlur
																															fullWidth
																															placeholder={
																																obj.placeholderKey
																															}
																															value={
																																conditionSet[
																																	obj.conditionName
																																][orIndex][andIndex]
																																	.selector
																															}
																															onChange={e =>
																																setNestedOption(
																																	obj.conditionName,
																																	e.target.value,
																																	orIndex,
																																	andIndex,
																																	'selector'
																																)
																															}
																														/>
																													)}
																												</Grid>
																												<Grid
																													item
																													xs={3}
																												>
																													<TextField
																														select
																														fullWidth
																														value={
																															conditionSet[
																																obj.conditionName
																															][orIndex][andIndex]
																																.operator
																														}
																														onChange={e => {
																															setNestedOption(
																																obj.conditionName,
																																e.target.value,
																																orIndex,
																																andIndex,
																																'operator'
																															);

																															const resetValue =
																																e.target.value ===
																																'is boolean'
																																	? 'true'
																																	: '';
																															setNestedOption(
																																obj.conditionName,
																																resetValue,
																																orIndex,
																																andIndex,
																																'value'
																															);
																														}}
																													>
																														{operators(
																															obj.conditionName
																														).map(operatorObj => (
																															<MenuItem
																																key={operatorObj.value}
																																value={operatorObj.value}
																															>
																																{t(
																																	`data_collection-deployment_editor_rule_drawer-operators-${operatorObj.label}`
																																)}
																															</MenuItem>
																														))}
																													</TextField>
																												</Grid>

																												<Grid
																													item
																													xs={4}
																												>
																													<TextField
																														fullWidth
																														placeholder={
																															obj.placeholderValue
																														}
																														value={
																															conditionSet[
																																obj.conditionName
																															][orIndex][andIndex].event
																														}
																														onChange={e =>
																															setNestedOption(
																																obj.conditionName,
																																e.target.value,
																																orIndex,
																																andIndex,
																																'event'
																															)
																														}
																														select
																													>
																														{eventOptions(
																															obj.conditionName
																														).map(({ label, value }) => (
																															<MenuItem
																																key={value}
																																value={value}
																															>
																																{t(
																																	`data_collection-deployment_editor_rule_drawer-events-${value}`
																																)}
																															</MenuItem>
																														))}
																													</TextField>
																												</Grid>
																											</Grid>
																										);
																									}}
																								</EditorNestedOrAndArrayConditions>
																							),
																						},
																					];
																				}),
																		  ]
																}
															/>
														</EditorOptionExpansionPanel>
													);
												})}
											</div>

											{editorType !== 'sdk' && (
												<>
													<Typography
														mt={3}
														mb={1}
														variant="subtitle2"
													>{t`data_collection-deployment_editor_rule_drawer-render_options-title`}</Typography>
													<Card
														className={classes.thenMargin}
														data-onboarding="form-placement"
													>
														<EditorOptionList
															options={[
																{
																	wrap: true,
																	custom: (
																		<>
																			<Box
																				width="100%"
																				display="flex"
																				alignItems="center"
																			>
																				<Typography
																					variant="body1"
																					display="inline"
																				>{t`data_collection-deployment_editor_rule_drawer-render_options-div-title`}</Typography>
																				<TextField
																					value={selectedRule.then[0].args[2]}
																					onChange={e =>
																						setRenderDiv({
																							value: e.target.value,
																						})
																					}
																					className={`${classes.inputInText} ${classes.renderInput}`}
																				/>
																			</Box>
																			<Typography
																				variant="caption"
																				color="textSecondary"
																				display="block"
																			>
																				{t`data_collection-deployment_editor_rule_drawer-render_options-div-helper`}
																			</Typography>
																		</>
																	),
																	divider: true,
																},
																{
																	show: isMultipleCssSelector(
																		selectedRule.then[0].args[2]
																	),
																	primary: t`data_collection-deployment_editor_rule_drawer-render_options-selector-primary`,
																	secondary: t`data_collection-deployment_editor_rule_drawer-render_options-selector-helper`,
																	onClick: e =>
																		setRenderOption({
																			option: 'useQuerySelectorAll',
																			value: !selectedRule.then[0].useQuerySelectorAll,
																		}),
																	action: (
																		<Switch
																			checked={selectedRule.then[0].useQuerySelectorAll}
																		/>
																	),
																	divider: true,
																	lessenTextOpacity:
																		!selectedRule.then[0].useQuerySelectorAll,
																},
																{
																	primary: t`data_collection-deployment_editor_rule_drawer-render_options-handle_placement-primary`,
																	secondary: t`data_collection-deployment_editor_rule_drawer-render_options-handle_placement-secondary`,
																	onClick: e =>
																		setRenderOption({
																			option: 'disableMakeDiv',
																			value: !selectedRule.then[0].disableMakeDiv,
																		}),
																	action: (
																		<Switch
																			checked={!selectedRule.then[0].disableMakeDiv}
																		/>
																	),
																	divider: true,
																	lessenTextOpacity: selectedRule.then[0].disableMakeDiv,
																},
															]}
														/>
													</Card>
												</>
											)}
										</>
									)}
									<Box height={48} />
								</>
							)}
						</div>
						{!app.session.onboarding_trial && selectedSurveyKey && (
							<>
								<ActionButton
									ref={addConditionFabRef}
									action="add"
									fab
									variant="extended"
									color="primary"
									label={t`data_collection-deployment_editor_rule_drawer-add_condition`}
									data-test-element="add-condition-button"
									data-track-event="deployment_editor_add_condition_set_button"
									onClick={() => {
										if (savedConditions.length === 0) {
											addCondition({
												ruleId: selectedRuleId,
												editorType,
											});
										} else {
											setAddCopiedConditionMenuOpen(true);
										}
									}}
									mb={2}
									className={classes.addCopiedConditionFab}
								/>
								<AddConditionPopover
									open={addCopiedConditionMenuOpen}
									anchorEl={addConditionFabRef.current}
									onClose={() => setAddCopiedConditionMenuOpen(false)}
									editorType={editorType}
								/>
							</>
						)}
					</HasPermissionWithEmptyState>
				</>
			</DrawerWithAppBar>

			<SimpleDialog
				open={showUnsavedDialog}
				onClose={() => setShowUnsavedDialog(false)}
				title={t`data_collection-deployment_editor_rule_drawer-unsaved_changes-title`}
				text={t`data_collection-deployment_editor_rule_drawer-unsaved_changes-content`}
				cancel={t`data_collection-deployment_editor_rule_drawer-unsaved_changes-cancel`}
				submit={t`data_collection-deployment_editor_rule_drawer-unsaved_changes-confirm`}
				onSubmit={closeDrawer}
			/>

			<SaveConditionDialog
				open={conditionToSave !== null}
				onClose={() => {
					setConditionToSave(null);
				}}
				conditionSet={conditionToSave}
			/>
		</>
	);
}
